<template>
  <div class="cookie-notification">
    <template v-if="confirmCookie === 1">
      <div class="cookie-notification__body">
        Для изменения настроек работы файлов COOKIE перейдите в настройки браузера и укажите желаемые параметры работы. Для блокировки файлов COOKIE вы также можете воспользоваться просмотром сайта в режиме «Инкогнито». Для запуска данного режима выберите соответствующую команду в меню браузера.
      </div>
      <div class="cookie-notification__btn">
        <div class="c-btn --transparent --thin --green" @click="$store.commit('setConfirmCookie', 2)">Согласен</div>
      </div>
    </template>
    <template v-else-if="confirmCookie === 2">
      <div class="cookie-notification__body">
        Мы бережно относимся к любой информации. Пожалуйста, ознакомьтесь с Пользовательским соглашением и подтвердите свое согласие с ним. В случае, если вы не согласны с указанным <a href="/docs/policy.docx" target="_blank">Пользовательским соглашением</a>, пожалуйста, покиньте сайт.
      </div>
      <div class="cookie-notification__btn">
        <div class="c-btn --transparent --thin --green" @click="$store.commit('setConfirmCookie', 3)">Согласен</div>
      </div>
    </template>
    <template v-else>
      <div class="cookie-notification__body">
        На нашем сайте мы используем файлы COOKIE. Для продолжения работы на сайте, пожалуйста, ознакомьтесь с <a href="/docs/cookie.docx" target="_blank">Политикой в отношении файлов COOKIE</a> и подтвердите свое согласие с ней. В случае, если вы не согласны с Политикой, вы не сможете продолжить работу на сайте. Обращаем ваше внимание, что вы можете самостоятельно менять настройки файлов COOKIE, как указано в настоящей Политике.
      </div>
      <div class="cookie-notification__btn">
        <div class="c-btn --transparent --thin --green" @click="$store.commit('setConfirmCookie', 2)">Согласен</div>
        <div class="c-btn --transparent --thin" @click="$store.commit('setConfirmCookie', 1)">Настроить</div>
      </div>
    </template>
  </div>
</template>

<script>
import {mapState} from "vuex";

export default {
  name: "VCookies",
  computed: {
    ...mapState({
      confirmCookie: state => state.confirmCookie
    })
  }
}
</script>

<style lang="sass">
@import "src/assets/css/media"

.cookie-notification
  z-index: 1000
  position: absolute
  bottom: 40px
  background: rgba(0, 0, 0, 0.8)
  padding: 10px
  display: flex
  align-items: center
  justify-content: space-between
  border-radius: 20px
  right: 50%
  transform: translateX(50%)
  width: 90vw
  flex-direction: column
  @include media-custom(425px)
    padding: 20px
    width: 90vw
  @include media-md
    flex-direction: row
  @include media-sm
    padding: 10px 20px
    transform: translateX(50%)
    max-width: 1100px
    min-width: 450px
  &__body
    color: #fff
    font-size: 12px
    line-height: 130%
    a
      color: #2DBF5E
  &__btn
    display: flex
  .c-btn
    padding: 5px 15px
    min-width: 70px
    margin-left: 20px
    //float: left

</style>