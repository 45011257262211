<template>
  <div class="alarm__body">
    <div class="alarm__cut">
      <div class="alarm__icon"></div>
      <div ref="alarm" class="alarm__content">
        <p class="alarm__title">{{ title }}</p>
        <p class="alarm__text" v-html="content"></p>
        <div class="alarm__message--wrong">{{ wrongMessage }}</div>
        <div class="alarm__message">
          {{ rightMessage }}
        </div>
      </div>
      <slot></slot>
    </div>
    <div v-if="showBtn" class="alarm__btn" @click="$emit('next')">
      {{ btnText }}
    </div>
  </div>
</template>

<script>
export default {
  name: "TAlarmBody",
  props: ["title", "content", "wrongMessage", "rightMessage", "btnText", "showBtn"],
};
</script>

<style lang="sass">
.alarm
  color: #333

  &__body
    transition: bottom 600ms ease, height 600ms ease, color 300ms ease
    z-index: 200
    position: absolute
    bottom: 50%
    width: 90%
    left: 5%
    transform: translateY(50%)

  &__cut
    background: #fff
    border-radius: 1rem
    padding-bottom: 20px
  //overflow: hidden
  //background: #ffffff
  //transition: bottom 600ms ease, height 600ms ease, color 300ms ease
  //padding: 15px
  //border-radius: 1rem

  &__icon
    position: relative
    width: 3.75rem
    height: 3.75rem
    background-image: url("/img/icon_star--red.png")
    background-size: contain
    background-repeat: no-repeat
    transform: translate(-50%, -50%)
    left: 50%
    top: 0

  &__title
    display: block
    font-size: 1.375rem
    font-weight: 600
    line-height: 140%
    margin: 0 0 1.3rem

  &__content
    text-align: center
  //overflow: hidden

  &__text
    text-align: left
    color: inherit
    margin: 0 1.4rem 1.5rem
    font-size: 1rem
    line-height: 130%

  &__message, &__message--wrong
    display: none
    padding: 0.625rem
    background: #DCF8C6
    width: calc(100% - 2.4rem)
    margin: 0.625rem auto
    text-align: left
    border-radius: 0.3rem
    box-shadow: 1px 1px 1px 0 rgba(0, 0, 0, .1)
    position: relative
    font-size: 1rem
    line-height: 1.3rem

    &:after
      content: ''
      background-image: url("/img/message_arrow.png")
      height: 9px
      width: 9px
      position: absolute
      right: -9px
      bottom: 5px
      z-index: 1

  &__message--wrong
    background: #FDBBA1

    &:after
      content: ''
      background-image: url("/img/message_arrow-wrong.png")
      height: 9px
      width: 9px
      position: absolute
      right: -9px
      bottom: 5px
      z-index: 1

  &__btn
    position: absolute
    bottom: -60px
    width: 90%
    text-align: center
    padding: 0.7rem 0.3rem
    transform: translateX(-50%)
    left: 50%
    background: #2DBF5E
    color: #ffffff
    font-size: 1rem
    border-radius: 1.5rem
    text-transform: uppercase
    font-weight: 600

    &:hover
      cursor: pointer
      background: #23a662

    &:before
      content: ''
      display: block

    &.close
      .alarm
        bottom: -200% !important
</style>
