<template>
  <div :class="[{ open: isOpen }, {'--chat': $route.name === 'chat'}, device]" class="main-menu">
    <div class="icon_menu" @click.stop="isOpen = !isOpen"></div>
    <ul>
      <li v-if="$route.name === 'chat'">
        <router-link :to="{ name: 'choice-branch' }">Начать заново</router-link>
      </li>
      <li v-else-if="$route.name !== 'home'">
        <router-link :to="{ name: 'home' }">Главная</router-link>
      </li>
      <li><router-link :to="{ name: 'memories' }">Воспоминания</router-link></li>
      <li><router-link :to="{ name: 'articles' }">Материалы</router-link></li>
      <li><router-link to="/advice">Советы</router-link></li>
      <li><router-link to="/about">О проекте</router-link></li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "MainMenu",
  props: {
    device: {
      type: String,
      default: "desktop",
    },
  },
  data: function() {
    return {
      isOpen: null,
    };
  },
  mounted() {
    const body = document.querySelector('body')

    // body.addEventListener('click', () => {
    //       this.isOpen = false
    //     })
    //
    // body.addEventListener('touchstart', () => {
    //       this.isOpen = false
    //     })
  },
  methods: {
    toggle: function() {
      this.isOpen = !!this.isOpen;
    },
    reset: function() {
      if (this.$route.path !== "/") this.$router.push({ path: "/" });
    },
  },
};
</script>

<style lang="sass">
@import "src/assets/css/media"

$mobile: 620px

.main-menu
  &.desktop
    position: absolute
    top: 1.5rem
    right: 1rem
    z-index: 200
    @include media-custom-max($mobile)
      float: right
      width: 2.375rem
      height: 2.375rem
      top: 1rem
      right: 1rem
      &.--chat
        display: none
    @media (max-height: 1000px)
      top: 1rem
    @media (max-height: 920px)
      top: 0.8rem
    @media (max-height: 710px) and (min-width: 541px)
      ul
        display: flex
        flex-direction: column
    ul
      list-style: none
      margin: 0
      padding: 0
      text-align: right
      @include media-custom-max($mobile)
        width: 10rem
        overflow: hidden
        display: none
        position: absolute
        right: 0
        top: 100%
        background: #ffffff
        border-radius: 0.4rem
        box-shadow: 0 20px 40px 0 rgba(0,0,0,.5)
      li
        display: inline-block
        margin: 0 1rem
        @include media-custom-max($mobile)
          display: block
      a
        font-size: 1rem
        text-decoration: none
        color: #A2A2A2
        margin: 0.7rem 0.1rem
        display: inline-block
        @include media-custom-max($mobile)
          display: block
        @media (max-height: 710px) and (min-width: 541px)
          margin: 0.3rem 0.1rem
        &:hover
          cursor: pointer
      .mobile-item
        display: none !important

    .icon_menu
      @include media-custom-max($mobile)
        content: ''
        position: absolute
        border-radius: 50%
        width: 2.375rem
        height: 2.375rem
        background: url("/img/icon__menu.svg") no-repeat
        background-size: contain
      &:hover
        cursor: pointer

    &.open
      .icon_menu
        background: url("/img/icon__menu-close--green.svg") no-repeat
        background-size: contain
      &:before
        display: block
      ul
        display: block
        margin-top: 1rem
    @media (max-width: 540px)
      display: block
      li
        display: block !important
    //@media (max-height: 1000px)
    //  top: 1rem
    //@media (max-height: 920px)
    //  top: 0.8rem
    //@media (max-height: 710px)
    //  ul
    //    display: flex
    //    flex-direction: column
    //  li
    //    display: block
    //    a
    //      display: block

  &.mobile
    float: right
    width: 2.375rem
    height: 2.375rem
    top: auto
    position: initial
    .icon_menu
      content: ''
      position: absolute
      border-radius: 50%
      width: 2.375rem
      height: 2.375rem
      background: url("/img/icon__menu.svg") no-repeat
      background-size: contain
      &:hover
        cursor: pointer
    &:before
      content: ''
      position: absolute
      left: 0
      top: 0
      width: 100%
      height: 100vh
      background: rgba(0,0,0,.5)
      display: none
    ul
      display: none
      position: absolute
      right: 1rem
      top: 100%
      background: #ffffff
      padding: 1rem
      border-radius: 0.4rem
      box-shadow: 0 20px 40px 0 rgba(0,0,0,.5)
      li
        display: none
        margin-bottom: 0
        a
          text-decoration: none
          color: #A2A2A2
          margin: 0 0 10px
        &.mobile-item
          display: block !important

    &.open
      .icon_menu
        background: url("/img/icon__menu-close.svg") no-repeat
        background-size: contain
      &:before
        display: block
      ul
        display: block
        li
          display: block


.home
  .reset
    display: none !important

</style>
