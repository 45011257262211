<template>
  <div class="chat-page" v-if="mainBranch">
    <div v-if="isNaN(step)" class="chat-app__intro">
      <c-icon-star class="chat-app__intro-icon" color="green"></c-icon-star>
      {{ mainBranch.introText }}
      <div class="c-btn" @click="nextStep">Перейти в чат</div>
      <div class="setup">
        <span>Ваш ребенок: {{name}}</span>
        <div class="c-btn --transparent" @click="$router.push({name: 'setup', params: {type: 'gender'}})">изменить</div>
      </div>
    </div>
    <div v-else :class="['chat-app', `--${currentStep.type || ''}`]">
      <chat-nav></chat-nav>
      <v-scroller v-model="topOffsetMessageBox" :inversion="true" :ext="0"></v-scroller>
      <div class="chat-app__messages">
        <chat-messages id="scrollable" :messages="messages" :style="{ bottom: topOffsetMessageBox + 'px' }"></chat-messages>
      </div>
      <chat-bar class="chat-app__type-in" :data="barData" @submit="addMessage"></chat-bar>
      <div class="chat-app__notification">
        <t-notification @close="nextStep" :id="idNotification"></t-notification>
      </div>
      <div class="chat-app__alarm">
        <t-alarm
          :intro-text="currentStep.introText || ''"
          :comment="currentStep.comment || ''"
          :wrong-message="currentStep.wrongMessage || ''"
          :right-message="currentStep.rightMessage || ''"
          @end="nextStep"
          @select="selectMessage"
        >
        </t-alarm>
      </div>
      <audio-player :file="'/sound/message.wav'" :play="mPlay"></audio-player>
      <audio-player :file="'/sound/alarm.wav'" :play="aPlay"></audio-player>
      <audio-player :file="'/sound/notification.wav'" :play="nPlay"></audio-player>
      <audio-player :file="'/sound/final.mp3'" :play="fPlay"></audio-player>
      <div :class="['chat-app__end', { '--active': finaleStage }]">
        <div
          :class="[
            'chat-app__end-screen',
            'chat-app__end-congratulation',
            { '--active': finaleStage === 'congratulation' },
          ]"
        >
          <div class="chat-app__end-title">Поздравляем!</div>
          <div class="chat-app__end-text">Вы обсудили с подростком важную тему и начали лучше понимать друг друга</div>
          <router-link
            class="c-btn --white"
            :to="{
              name: 'chat',
              params: {
                gender: this.gender,
                branch: this.branch,
                end: this.end,
                step: this.steps.length,
                finaleStage: 'action'
              }
            }"
            >Класс, идём дальше!
          </router-link>
          <div class="chat-app__end-img"></div>
        </div>

        <div :class="['chat-app__end-screen', 'chat-app__end-action', { '--active': finaleStage === 'action' }]">
          <div class="chat-app__end-action__links">
            <router-link class="c-btn --white --block" :to="{ name: 'choice-branch' }">
              поговорить на&nbsp;другие темы
            </router-link>
            <router-link class="c-btn --white --block" :to="{ name: 'articles' }"
              >Изучить материалы по&nbsp;теме
            </router-link>
            <router-link class="c-btn --white --block" :to="{ name: 'memories' }"
              >Погрузиться в&nbsp;воспоминания
            </router-link>
<!--            <router-link class="c-btn &#45;&#45;white &#45;&#45;block" :to="{ name: 'articles', hash: '#video' }">смотреть видео</router-link>-->
            <router-link
              class="c-btn --transparent --block"
              :to="{
                name: 'chat',
                params: {
                  gender: this.gender,
                  branch: this.branch,
                  end: this.end,
                  step: this.steps.length,
                  finaleStage: 'share'
                }
              }"
              >поделиться
            </router-link>
          </div>
          <div class="chat-app__end-img">
            <img src="" alt="" />
          </div>
        </div>

        <div :class="['chat-app__end-screen', 'chat-app__end-share', { '--active': finaleStage === 'share' }]">
          <div class="chat-app__end-share__title">Расскажите друзьям о&nbsp;CONVERTER!</div>
          <div class="chat-app__end-share__block">
            <div class="chat-app__end-share__text">
              {{ mainBranch.shareText || "" }}
            </div>
            <div class="chat-app__end-share__img" :style="{backgroundImage: `url(/collages/_share-bg/${mainBranch.img}.jpg`}"></div>
          </div>
          <div class="chat-app__end-share__links">
            <a class="chat-app__end-share__link --vk" target="_blank" :href="`https://vk.com/share.php?url=${shareLink}&title=${mainBranch.shareText}`"></a>
            <a class="chat-app__end-share__link --fb" target="_blank" :href="`https://www.facebook.com/sharer.php?u=${shareLink}`"></a>
            <a class="chat-app__end-share__link --tw" target="_blank" :href="`https://twitter.com/intent/tweet?url=${shareLink}`"></a>
            <a class="chat-app__end-share__link --ok" target="_blank" :href="`https://connect.ok.ru/offer?url=${shareLink}`"></a>
            <a class="chat-app__end-share__link --wa" target="_blank" :href="`https://api.whatsapp.com/send?text=${shareLink}`"></a>
            <a class="chat-app__end-share__link --tl" target="_blank" :href="`https://t.me/share/url?url=${shareLink}`"></a>
            <a class="chat-app__end-share__link --vb" target="_blank" :href="`viber://forward?text=${shareLink}`"></a>
            <a class="chat-app__end-share__link --fm" target="_blank" :href="`fb-messenger://share/?link=${shareLink}%26rd%3D1`"></a>
          </div>
          <router-link
            class="c-btn --transparent --block"
            :to="{
              name: 'chat',
              params: {
                gender: this.gender,
                branch: this.branch,
                end: this.end,
                step: this.steps.length,
                finaleStage: 'action',
              },
            }"
            >Закрыть
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters, mapState} from "vuex";
import ChatNav from "@/components/chat/chat-nav";
import ChatMessages from "@/components/chat/chat-messages";
import ChatBar from "@/components/chat/chat-bar";
import TNotification from "@/components/type/t-notification";
import TAlarm from "@/components/type/alarm/t-alarm";
import CIconStar from "@/components/design/c-icon-star";
import VScroller from "@/components/v-scroller";
import AudioPlayer from "@/components/audio-player";

export default {
  name: "ChatPage",
  components: {AudioPlayer, VScroller, CIconStar, TAlarm, TNotification, ChatNav, ChatMessages, ChatBar },
  props: ["gender", "branch", "step", "end", "finaleStage"],
  data: function() {
    return {
      messages: [],
      barData: {},
      timer1: null,
      topOffsetMessageBox: 0,
      idNotification: null,
      mPlay: false,
      aPlay: false,
      nPlay: false,
      fPlay: false,
    };
  },
  computed: {
    ...mapState({
      mainBranch: state => state.branch,
      name: state => state.name
    }),
    ...mapGetters({
      steps: "steps",
    }),
    currentStep: function() {
      const step = this.steps.filter(step => step.id === this.step)[0];
      return step ? step : {};
    },
    shareLink: function () {
      if (this.mainBranch) {
        return `https%3A%2F%2Fchatconverter.space%2F%3Fs%3D${this.mainBranch.idBranch}`
      } return '';
    }
  },
  watch: {
    step: function(n, o) {
      if (isNaN(o)) {
        this.$store.commit("setShowPhoneOpacity", false);
      }

      if (isNaN(n)) {
        this.$store.commit("setShowPhoneOpacity", true);
      }

      if (n - o !== 1) {
        this.forwardStep();
      }

      this.setStep();
    },
  },
  async mounted() {
    await this.$store.dispatch("loadSteps", {
      gender: this.gender,
      idBranch: this.branch,
      idBranchEnd: this.end,
    });
    this.$store.commit("setBackground", null);
    this.$store.commit("setShowPhoneOpacity", isNaN(this.step));
    this.forwardStep();
    this.playStep();
  },
  beforeDestroy() {
    clearTimeout(this.timer1)
  },
  methods: {
    nextStep: async function() {
      this.topOffsetMessageBox = 0;
      const next = this.step + 1 || 0;
      if (this.steps.length - 1 >= next)
        await this.$router.push({
          name: "chat",
          params: {
            gender: this.gender,
            branch: this.branch,
            end: this.end,
            step: next,
          },
          meta: {
            test: 'title'
          }
        });
      else {
        await this.$router.push({
          name: "chat",
          params: {
            gender: this.gender,
            branch: this.branch,
            end: this.end,
            step: this.steps.length,
            finaleStage: "congratulation",
          },
        });
        this.fPlay = !this.fPlay
      }
    },
    setStep: function() {
      if (this.timer1 !== null) clearTimeout(this.timer1);

      //this.forwardStep()
      this.barData = {};
      this.playStep();
    },
    forwardStep: function() {
      const end = typeof this.step ? this.step : this.steps.length;

      let isWrong = false;
      let wrongMessageCount = 3;

      this.messages = this.steps
          .filter(step => {
            return step.id < end && ["message", "sticker", "sticker-pack", "alarm"].includes(step.type);
          })
          .reverse()
          .map((i) => {
            if (i.type === 'alarm') {
              isWrong = true
            }

            if (isWrong && wrongMessageCount) {
              wrongMessageCount--
              return false
            } else return i
          })
          .filter(i => i)
          .reverse()
          .map((step, idx, arr) => {
            switch (step.type) {
              case "sticker-pack":
                return {
                  ...step,
                  type: "sticker",
                  status: "read",
                  img: step.stickers[0],
                };
              case "auto-type-in":
                return {
                  ...step,
                  type: "message",
                  status: "read",
                };
              default:
                return {
                  ...step,
                  status: "read",
                };
            }
          })
    },
    playStep: function () {
      const step = this.steps.filter(step => step.id === this.step)[0];
      if (!step) return false;

      switch (step.type) {
        case "message":
          this.playMessage(step);
          break;
        case "sticker":
          this.playMessage(step);
          break;
        case "sticker-pack":
          this.barData = step;
          break;
        case "auto-type-in":
          this.barData = step;
          break;
        case "type-in":
          document.getElementById("user-input").focus();
          this.barData = step;
          break;
        case "notification":
          this.nPlay = !this.nPlay
          this.idNotification = step.idMemory
          break;
        case "alarm":
          this.aPlay = !this.aPlay
          break;
      }

    },
    playMessage: function(data) {
      data = {
        delayRead: 1000,
        delayDelivery: 1000,
        delayWrite: 1000,
        delayNextStep: 1000,
        status: data.direction === "in" ? "write" : "",
        direction: "out",
        ...data,
      };

      this.messages.push(data);

      setTimeout(() => {
        data.status = "delivered";

        if (data.direction === "out") {
          setTimeout(() => {
            data.status = "read";
          }, data.delayRead);
        } else {
          this.mPlay = !this.mPlay
        }
      }, data.delayDelivery);

      // NEXT STEP
      this.timer1 = setTimeout(() => {
        this.nextStep();
      }, data.delayNextStep);
    },
    addMessage: function(data) {
      this.barData = {};
      this.playMessage(data);
    },
    selectMessage: async function(idx) {
      await this.$store.dispatch("loadSteps", {
        gender: this.gender,
        idBranch: this.branch,
        idBranchEnd: idx,
      });

      this.messages.splice(-2, 2)

      await this.$router.push({
        name: "chat",
        params: {
          gender: this.gender,
          branch: this.branch,
          end: idx,
          step: this.step,
        },
      });
    },
  },
};
</script>

<style lang="sass">
@import "src/assets/css/media"
.chat-page
  color: #fff
  display: flex
  flex-direction: column
  align-items: center
  justify-content: center
  height: 100%

.chat-app
  display: flex
  flex-direction: column
  justify-content: stretch
  height: 100%
  width: 100%
  background: url('/img/chat__bg.jpg') center center
  background-size: 300px

  &__intro
    display: flex
    flex-direction: column
    align-items: center
    text-align: center
    width: 80%
    margin-top: -100px

    &-icon
      margin-bottom: 2rem

    .c-btn
      margin-top: 2rem

    .setup
      position: absolute
      bottom: 40px
      .c-btn
        margin-top: 1rem

  &.--notification
    .chat-app__notification
      bottom: 35px

  &.--alarm
    .chat-app__alarm
      bottom: 0

    .chat-app__messages
      .message:nth-last-of-type(-n+2)
        .message__body
          background-color: #FDBBA1

          &:after
            background-image: url("/img/message_arrow-wrong.png")

          .message__time
            color: #DD8F7C

          .message__text
            color: #5B2B17

          .message__status:before
            background-image: url("/img/message-wrong.png")
        &.--in
          .message__body:after
            background-image: url("/img/message_arrow-wrong--in.png")

  &__messages
    height: 100%
    position: relative

  &__notification
    transition: bottom 700ms ease-in-out
    position: absolute
    bottom: -300px
    width: 100%
    z-index: 100
    padding: 0 5%

  &__alarm
    transition: bottom 700ms ease-in-out
    position: absolute
    bottom: -100vh
    width: 100%
    z-index: 200
    padding: 0
    height: 100%

    .step-alarm__inner
      position: relative
      padding: 0
      width: 100%
      height: 100%
      margin-bottom: 20px
      overflow: hidden
      border-radius: 10px

  &__end
    position: absolute
    bottom: -100%
    width: 100%
    z-index: 200
    padding: 0
    height: 100%
    background: #2DBF5E

    &.--active
      bottom: 0
      transition: bottom 700ms ease-in-out

    &-title
      font-size: 2rem
      margin-bottom: 20px
      margin-top: 150px

    &-text
      font-size: 1.2rem
      margin-bottom: 1.4rem

    &-img
      background: url('/img/share.png') center bottom no-repeat
      background-size: contain
      width: 100%
      height: 40%
      margin-top: 30px

    &-action
      &__links
        margin-top: 3rem


    &-share
      padding-bottom: 2.5rem !important
      &__title
        font-size: 1.4rem
        font-weight: bold
        margin-top: 1rem
        line-height: 140%
        @include media-h-max-custom(600px)
          margin-top: 1.5rem
        @include media-sm
          margin-top: 2rem
        @include media-md
          margin-top: 2rem
        @include media-lg
          margin-top: 3rem


      &__block
        border-radius: 12px
        overflow: hidden
        margin-top: 10px

      &__text
        background: #fff
        color: #000
        padding: 15px 20px

      &__img
        height: 200px
        background-size: cover
        background-position: center center

      &__links
        display: flex
        flex-wrap: wrap
        width: 100%
        margin-top: 20px

      &__link
        width: 25%
        height: 3rem
        margin-bottom: 1rem
        background-position: center center
        background-size: 3rem
        background-repeat: no-repeat

        &.--vk
          background-image: url("/img/icon_soc-vk.svg")
        &.--fb
          background-image: url("/img/icon_soc-fb.svg")
        &.--tw
          background-image: url("/img/icon_soc-tw.svg")
        &.--ok
          background-image: url("/img/icon_soc-ok.svg")
        &.--in
          background-image: url("/img/icon_soc-in.svg")
        &.--fm
          background-image: url("/img/icon_soc-fm.svg")
        &.--tl
          background-image: url("/img/icon_soc-tl.svg")
        &.--wa
          background-image: url("/img/icon_soc-wa.svg")
        &.--vb
          background-image: url("/img/icon_soc-vb.svg")

    &-screen
      display: none

      &.--active
        height: 100%
        display: flex
        justify-content: space-between
        flex-direction: column
        align-items: center
        padding: 30px 25px 0
        text-align: center
</style>
