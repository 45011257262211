<template>
  <div id="convertor" :class="[{ split: cssSplitApp, mounted: cssMountedApp, finish: cssFinishApp }, cssActiveApp]">
    <div :class="['root-background', cssBackground]"></div>
    <main-menu></main-menu>
    <dev-menu v-if="$route.name !== 'editor'"></dev-menu>
    <slot></slot>
  </div>
</template>

<script>
import { mapState } from "vuex";
import MainMenu from "@/components/MainMenu";
import DevMenu from "@/components/DevMenu";

export default {
  name: "RootWrapper",
  components: { DevMenu, MainMenu },
  computed: {
    ...mapState({
      cssActiveApp: state => state.cssActiveApp,
      cssSplitApp: state => state.cssSplitApp,
      cssMountedApp: state => state.cssMountedApp,
      cssFinishApp: state => state.cssFinishApp,
      cssBackground: state => (state.background ? `--${state.background}` : null),
    }),
  },
};
</script>

<style lang="sass">
\:root
  --chat_bg_color: #F8F7F7
  --color_green: #2DBF5E
  --bg_green: #00AD5E
  --bg_red: #DB4747
  --message_bg_green: #DCF8C6
  --message_bg_red: #FDBBA1
html
  font-size: 15px
  @media (max-width: 425px)
    font-size: 14px
  @media (max-width: 320px)
    font-size: 13px
  @media (min-width: 426px) and (max-height: 850px)
    font-size: 14px
  @media (min-width: 426px) and (max-height: 800px)
    font-size: 13px
  @media (min-width: 426px) and (max-height: 750px)
    font-size: 12px
  @media (min-width: 426px) and (max-height: 550px)
    font-size: 11px
  @media (min-width: 426px) and (max-height: 500px)
    font-size: 10px

body
  margin: 0
  padding: 0
  font-family: "Open Sans", sans-serif
  font-size: 1rem
  line-height: 1.4rem
  box-sizing: border-box

*
  box-sizing: border-box

html, body, #app, #convertor
  height: 100%
  overflow: hidden

html
  -ms-overflow-style: -ms-autohiding-scrollbar

.root
  &-background
    height: 100%
    width: 100%
    position: absolute
    top: 0
    left: 0
    z-index: 0
    opacity: 1
    transition: opacity 800ms ease-in-out
    background-color: #ededed
    background-position: center center
    background-size: cover
    &.--home
      background-image: url("/img/home_bg.jpg")
      @media (max-width: 425px)
        background-image: url("/img/home_bg--mobile.png")
    &.--editor
      background: #929292


#convertor
  position: relative
  .phone__app.chat
    opacity: 0
  &.chat
    .phone__app.chat
      opacity: 1
  &.split
    .phone__app
      transform: scale(0.9,0.9)
      border-radius: 30px
  /*overflow: hidden*/
  &.collage
    &.finish
      .phone__app.collage
        &:after
          display: none
    &.mounted
      .scroll-down
        display: block
      .phone
        background: transparent
      .content
        opacity: 1
      .phone__app.collage
        background: transparent
        &:after
          opacity: 0
      .phone__content
        &:before
          opacity: 0
        &:after
          opacity: 0
</style>
