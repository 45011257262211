<template>
  <div class="messages__wrapper">
    <component :is="`t-${message.type}`" v-for="message in messages" :key="message.id" :data="message"> </component>
  </div>
</template>

<script>
import TMessage from "@/components/type/t-message";
import TSticker from "@/components/type/t-sticker";
export default {
  name: "ChatMessages",
  components: { TSticker, TMessage },
  props: ["messages"],
  computed: {
    cMessages: function() {
      return this.messages.map(m => {
        return {};
      });
    },
  },
};
</script>

<style lang="sass">
.messages__wrapper
  background-size: cover
  color: #333
  display: flex
  flex-direction: column
  width: 100%
  flex-wrap: nowrap
  position: absolute
  bottom: 0
  z-index: 10
  margin-bottom: 20px
</style>
