<template>
  <div class="page">
    <div class="page-bg" v-if="bg">
      <div class="page-bg__left"></div>
      <div class="page-bg__right"></div>
    </div>
    <slot name="bg"></slot>
    <div class="page-wrapper">
      <div class="page-header">
        <slot name="header"></slot>
      </div>
      <div class="page-body">
        <slot name="body"></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "WrapperPage",
  props: ['bg']
}
</script>

<style lang="sass">
@import "src/assets/css/media"
.page
  background: #fff
  min-height: 100%
  overflow-y: scroll

  &-bg
    width: 100%
    z-index: 1

    &__left
      width: 20%
      height: 471px
      background: url(/img/page_left.png) left top no-repeat
      background-size: contain
      position: absolute
      top: 0
      left: 0
      @include media-sm
        width: 22%
      @include media-lg
        width: 100%

    &__right
      width: 20%
      height: 388px
      background: url(/img/page_right.png) right top no-repeat
      background-size: contain
      position: absolute
      top: 0
      right: 0
      @include media-sm
        width: 22%
      @include media-lg
        width: 100%

  &-wrapper
    position: relative
    z-index: 10
    width: 90%
    max-width: 1440px
    margin: 0 auto
    @include media-sm
      width: 95%
    @include media-lg
      width: 98%

  &-header
    display: flex
    flex-direction: column
    align-items: center
    padding-top: 60px
    text-align: center
    max-width: 700px
    margin: 0 auto 70px
    font-size: 15px
    line-height: 150%
    @include media-md
      font-size: 18px
      padding-top: 100px
    @include media-lg
      font-size: 20px
      padding-top: 140px

    h1
      font-size: 30px
      line-height: 130%
      @include media-md
        font-size: 40px
      @include media-lg
        font-size: 50px

</style>