<template>
  <img class="sticker__img" :src="path" alt="" />
</template>

<script>
export default {
  name: "VSticker",
  props: ["sticker"],
  computed: {
    path: function() {
      return "/img/stickers/" + this.sticker + ".png";
    },
  },
};
</script>

<style lang="sass">
.sticker__img
  width: 100%
</style>
