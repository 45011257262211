<template>
  <w-message :direction="data.direction" :status="data.status" :time="data.time" :type="'message'">{{ data.text }}</w-message>
</template>

<script>
import WMessage from "@/components/wrapper/w-message";
export default {
  name: "TMessage",
  components: { WMessage },
  props: ["data"],
};
</script>

<style lang="sass">
.message__text
  font-size: 1rem
</style>
