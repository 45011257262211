<template>
  <audio
      ref="audio"
      :loop="innerLoop"
      :src="file"
      preload="auto"
      style="display: none"
      @play="playing = true"
      @pause="playing = false"
  ></audio>
</template>

<script>
import {mapState} from "vuex";

export default {
  name: "AudioPlayer",
  props: {
    file: {
      type: String,
      default: null,
    },
    autoPlay: {
      type: Boolean,
      default: false,
    },
    loop: {
      type: Boolean,
      default: false,
    },
    play: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    currentSeconds: 0,
    durationSeconds: 0,
    innerLoop: false,
    playing: false,
    volume: 100,
  }),
  watch: {
    play(value) {
      this.$refs.audio.currentTime = 0
      this.playAudio()
    },
  },
  methods: {
    playAudio() {
      this.$refs.audio.play();
    },
  },
};
</script>

<style lang="sass"></style>
