<template>
  <div class="iframe">
    <iframe src="/pages/advice/" frameborder="0" width="100%" height="100%"></iframe>
  </div>
</template>

<script>
export default {
  name: "AdvicePage",
};
</script>

<style lang="sass">
.iframe
  height: 100%
  background: #fff
</style>
