<template>
  <div :class="['icon-star', classIcon]"></div>
</template>

<script>
export default {
  name: "CIconStar",
  props: {
    color: {
      type: String,
      default: "green",
    },
  },
  computed: {
    classIcon: function() {
      return `--${this.color}`;
    },
  },
};
</script>

<style lang="sass">
.icon-star
  display: inline-block
  width: 4.75rem
  height: 4.75rem
  background-size: contain
  background-repeat: no-repeat
  background-position: center center
  opacity: 1
  transition: opacity 500ms ease-in-out, top 400ms ease-in-out
  &.--green
    background-image: url("/img/icon_star--green.svg")
  &.--red
    background-image: url("/img/icon_star--red.svg")
</style>
