<template>
  <div :class="['chat-bar', cssClass]" :style="cStyle">
    <div class="chat-bar__input">
      <div class="chat-bar__input-body">
        <div class="chat-bar__input-icon --sticker"></div>
        <div class="chat-bar__input-field">
          <chat-auto-type-in
              v-if="autoTypingText"
              :text="autoTypingText"
              @onComplete="isAutoTypingCompleted = true"
          >
          </chat-auto-type-in>
          <div
            contentEditable="true"
            id="user-input"
            @input="userTextEdit"
            class="chat-bar__input-type-in"
            @keydown.ctrl.enter="sendMessage"
            ref="userInput"
          />
          <div class="chat-bar__input-placeholder" v-if="!userText">Введите свой ответ</div>
        </div>
      </div>
      <div :class="['chat-bar__input-send', { '--active': isSendActive }]" @click="sendMessage"></div>
    </div>
    <div v-if="data && data.stickers" class="chat-bar__sticker-pack">
      <div
        v-for="(sticker, idx) in data.stickers"
        :key="idx"
        class="sticker-pack__item"
        @click="choiceSticker(sticker)"
      >
        <v-sticker :sticker="sticker"></v-sticker>
      </div>
    </div>
  </div>
</template>

<script>
import ChatAutoTypeIn from "@/components/chat/chat-auto-type-in";
import VSticker from "@/components/basic/v-sticker";

export default {
  name: "ChatBar",
  components: { VSticker, ChatAutoTypeIn },
  props: ["data"],
  data: function() {
    return {
      isAutoTypingCompleted: false,
      userText: "",
      height: null
    };
  },
  computed: {
    autoTypingText: function() {
      if (this.data && this.data.type === "auto-type-in") return this.data.text;
      return "";
    },
    cssClass: function() {
      return this.data ? `--${this.data.type}` : "";
    },
    isSendActive: function() {
      return this.isAutoTypingCompleted || this.data.type === "sticker-pack" || this.userText.length;
    },
    cStyle: function () {
      if (this.height === null) return null
      return {
        height: 80 + this.height + 'px'
      }
    }
  },
  watch: {
    userText: function (n, o) {
      if (!n) {
        this.height = null
        return false
      }

      if (this.$refs.userInput.clientHeight > 20)
        this.height = this.$refs.userInput.clientHeight - 20

    }
  },
  methods: {
    userTextEdit: function (e) {
      this.userText = e.target.innerText
    },
    sendMessage: function() {
      if (this.isAutoTypingCompleted) {
        this.emitMessage({
          ...this.data,
          type: "message",
        });
      } else if (this.userText.length) {
        this.emitMessage({
          ...this.data,
          text: this.userText,
          type: "message",
        });
      }
    },
    choiceSticker: function(id) {
      this.emitMessage({
        type: "sticker",
        img: id,
      });
    },
    emitMessage: function(data) {
      if (!this.isSendActive) return false;
      this.$emit("submit", data);
      this.isAutoTypingCompleted = false;
      this.userText = "";
      this.$refs.userInput.innerText = ''
    },
  },
};
</script>

<style lang="sass">
.chat-bar
  width: 100%
  transition: height 150ms ease-in
  padding-bottom: 20px
  background: #FBFAFA
  z-index: 55
  height: 65px
  //padding-top: 7px
  //@media (max-width: 425px)
    padding-bottom: 0.625rem
  @media (max-width: 425px)
    padding-bottom: 5px
    height: 45px

  &.--sticker-pack
    height: 300px

  &.--auto-type-in
    height: auto

  &.--type-in
    .chat-bar__input-body
      border-color: green
    .chat-bar__input-type-in
      font-size: 0.9rem
      display: block
      width: 100%
      &::placeholder
        color: #606060
      @media (max-width: 425px)
        //font-size: 16px

  &__sticker-pack
    background-color: #F0F0F0
    height: 100%
    width: 100%
    color: #000
    display: flex
    align-content: flex-start
    flex-wrap: wrap
    padding: 0 0.9rem

    .sticker-pack__item
      width: calc(100% / 3)
      padding: 10px

      &:hover
        cursor: pointer


  &__input
    width: 100%
    display: flex
    justify-content: space-between
    align-items: flex-start
    margin: 5px 0

    &-type-in
      display: none
      border: none
      outline: none
      z-index: 10
      position: relative

    &-placeholder
      position: absolute
      top: 0
      color: #606060
      z-index: 1
      display: none

    &-body
      margin-left: 1.3rem
      margin-right: 0.7rem
      padding: 5px 0
      background: #fff
      width: 100%
      min-height: 1.9rem
      border-radius: 1.2rem
      border: 1px solid #DDDDDD
      color: #000
      font-size: 1rem
      line-height: 130%
      display: flex
      align-items: flex-start
      @media (max-width: 425px)
        margin-left: 10px

    &-field
      width: calc(100% - 50px)
      padding-right: 10px
      position: relative

    &-icon
      margin-right: 10px
      margin-left: 10px
      width: 18px
      height: 18px
      background: url("/img/icon_chat-type-in.svg") center center no-repeat
      background-size: contain

    &-send
      background: url("/img/icon_type-in__send.svg") center center no-repeat
      background-size: contain
      width: 34px
      height: 29px
      border-radius: 50%
      margin-right: 20px
      @media (max-width: 425px)
        margin-right: 10px

      &.--active
        background-image: url("/img/icon_type-in__send--active.svg")

        &:hover
          cursor: pointer
.--type-in
  .chat-bar
    height: 90px
    @media (max-width: 425px)
      height: 55px
  .chat-bar__input-icon
    background-image: url(/img/icon_chat-type-in.svg)
  .chat-bar__input-placeholder
    display: block

.--auto-type-in
  .chat-bar
    height: auto

.--sticker-pack
  .chat-bar__input-icon
    background-image: url(/img/icon_chat-sticker.svg)

.typed-cursor
  display: none !important
</style>
