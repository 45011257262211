<template>
  <div id="app" @click.shift.ctrl.alt.stop="toggleDebug">
    <root-wrapper>
      <transition name="fade">
        <router-view class="root"></router-view>
      </transition>
    </root-wrapper>
    <v-cookies v-if="confirmCookie !== 3"></v-cookies>
  </div>
</template>

<script>
import RootWrapper from "@/components/RootWrapper";
import VCookies from "@/components/v-cookies";
import {mapState} from "vuex";
import api from "@/api";
export default {
  name: "App",
  components: {VCookies, RootWrapper },
  computed: {
    ...mapState({
      confirmCookie: state => state.confirmCookie
    })
  },
  methods: {
    toggleDebug: function() {
      this.$store.commit("toggleDebug");
    },
  },
};
</script>

<style lang="sass">
.fade-enter, .fade-leave-to
  opacity: 0
.fade-enter-active, .fade-leave-active
  transition: opacity .2s

.root
  position: relative
  z-index: 1
  height: 100%

.c-btn
  text-align: center
  padding: 1rem 1.3rem
  display: block
  max-width: 320px
  min-width: 110px
  margin: 0.5rem auto
  background: #2DBF5E
  color: #ffffff
  font-size: 1rem
  border-radius: 2rem
  text-transform: uppercase
  font-weight: bold
  transition: opacity 500ms ease-in-out, bottom 400ms ease-in-out
  text-decoration: none

  a
    color: inherit
    text-decoration: none

  &:hover
    background: #23a662
    cursor: pointer
    color: #ffffff
    text-decoration: none

  &.--block
    width: 100%

  &.--white
    background: #fff
    color: #000000

    &:hover
      background: #fff
      color: #2DBF5E

  &.--transparent
    background: transparent
    color: #fff
    border: 2px solid #fff

    &:hover
      background: #fff
      color: #2DBF5E

  &.--thin
    border-width: 1px
    font-weight: normal

  &.--green
    border-color: #2CAC56
    color: #2CAC56
</style>
