import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
import api from "@/api";
import router from "@/router";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    background: "home",
    showPhoneOpacity: true,
    messages: [],
    name: null,
    gender: null,
    idBranch: null,
    idBranchEnd: 0,
    branch: null,
    branchEnd: null,
    debug: false,
    confirmCookie: false,
    stopAudio: true,
    token: null
  },
  mutations: {
    setIdBranch: function(state, payload) {
      state.idBranch = payload;
    },
    setIdBranchEnd: function(state, payload) {
      state.idBranchEnd = payload;
    },
    setBranch: function(state, payload) {
      state.branch = payload;
    },
    setBranchEnd: function(state, payload) {
      state.branchEnd = payload;
    },
    setBackground: function(state, payload) {
      state.background = payload;
    },
    setShowPhoneOpacity: function(state, payload) {
      state.showPhoneOpacity = payload;
    },
    setGender: function(state, payload) {
      state.gender = payload;
    },
    setName: function(state, payload) {
      state.name = payload;
    },
    toggleDebug: function(state) {
      state.debug = !state.debug;
    },
    setConfirmCookie: function (state, payload) {
      state.confirmCookie = payload
    },
    setStopAudio: function (state) {
      state.stopAudio = !state.stopAudio
    },
    setToken: function (state, payload) {
      state.token = payload
    }
  },
  getters: {
    steps: state => {
      let steps = [];
      if (state.branch && state.branch.steps) {
        steps = [...steps, ...state.branch.steps];
      }
      if (state.branchEnd && state.branchEnd.steps) {
        steps = [...steps, ...state.branchEnd.steps];
      }

      return steps.map((i, idx) => {
        return {
          ...i,
          id: idx,
        };
      });
    },
  },
  actions: {
    loadSteps: async function(ctx, payload) {
      const { gender, idBranch, idBranchEnd } = payload;

      if (idBranch) {
        const id = `${gender}-${idBranch}`;
        const data = await api.getBranch(id);
        if (data.length)
          ctx.commit("setBranch", data[0]);
        else
          ctx.commit("setBranch", null);
      }

      if (typeof idBranchEnd === "number" && !isNaN(idBranchEnd)) {
        const id = `${gender}-${idBranch}_${idBranchEnd}`;
        const data = await api.getBranch(id);
        if (data.length)
          ctx.commit("setBranchEnd", data[0]);
        else
          ctx.commit("setBranchEnd", null);
      }
    },
  },
  plugins: [createPersistedState()],
});
