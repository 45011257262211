<template>
  <w-message :direction="data.direction" :status="data.status" :time="data.time" :type="'sticker'">
    <div class="message__img">
      <v-sticker :sticker="data.img"></v-sticker>
    </div>
  </w-message>
</template>

<script>
import WMessage from "@/components/wrapper/w-message";
import VSticker from "@/components/basic/v-sticker";

export default {
  name: "TSticker",
  components: { VSticker, WMessage },
  props: ["data"],
};
</script>

<style lang="sass">
.message
  &.--sticker
    background: transparent
    box-shadow: none
    overflow: hidden
  &__img
    height: 8rem
    width: 8rem
</style>
