<template>
  <div class="t-alarm">
    <t-alarm-wrapper
      :class="['step-alarm__inner', step]"
      :step="step"
      :title="title"
      :content="content"
      :btn="btn"
      :wrong-message="wrongMessage"
      :right-message="rightMessage"
      @next-step="next"
    ></t-alarm-wrapper>
    <slot name="control" :next="next" :prev="prev" :step="step">
      control
    </slot>
  </div>
</template>

<script>
import TAlarmWrapper from "@/components/type/alarm/t-alarm-wrapper";

export default {
  name: "TAlarm",
  components: { TAlarmWrapper },
  props: ["wrongMessage", "rightMessage", "introText", "comment"],
  data: function() {
    return {
      stepIdx: 0,
      chosenMessage: null,
    };
  },
  computed: {
    steps: function() {
      return [
        {
          id: "show",
          title: "Предупреждение",
          content: "Похоже, эти сообщения могут привести к конфликту",
        },
        {
          id: "what",
          title: "Комментарий психолога",
          content: this.introText,
          btn: "Конвертировать сообщение",
        },
        {
          id: "convert",
          title: "Конвертируем",
          cb: () => {
            setTimeout(_ => {
              this.next();
            }, 800);
          },
        },
        {
          id: "done",
          title: "Готово!",
          text: "Выберите один из трех вариантов бережного ответа:",
          content: this.rightMessage,
          btn: "Выбрать",
        },
        {
          id: "description",
          title: "Почему так?",
          content: this.comment,
          btn: "Отправить сообщение",
        },
        {
          id: "close",
        },
      ];
    },
    step: function () {
      return this.steps[this.stepIdx].id;
    },
    title: function () {
      return this.steps[this.stepIdx].title || null;
    },
    content: function () {
      return this.steps[this.stepIdx].content || null;
    },
    btn: function () {
      return this.steps[this.stepIdx].btn || null;
    },
  },
  watch: {
    stepIdx: function (idx, oIdx) {
      if (idx < oIdx || !this.steps[idx].cb) return false;
      this.steps[idx].cb();
    },
    introText: function (n, o) {
      if (n === o) this.stepIdx = 0;
    },
  },
  methods: {
    refreshHeight: function() {
      setTimeout(() => {
        this.height = this.$refs.alarm.clientHeight;
      }, 1);
    },
    prev: function () {
      if (this.stepIdx > 0) this.stepIdx--;
    },
    next: function (idx) {
      // Выбор варианта ответа
      if (this.stepIdx === 3) this.$emit("select", idx);

      if (this.stepIdx < this.steps.length - 1) this.stepIdx++;

      if (this.stepIdx === this.step.length) {
        setTimeout(_ => {
          this.$emit("end");
          this.stepIdx = 0;
        }, 200);
      }
    },
  },
};
</script>

<style lang="sass">
.t-alarm
  width: 100%
  height: 100%
  position: relative

.step-alarm__inner

  &.start
    .alarm__body
      bottom: -10rem !important

  &.show, &.start
    &:before
      opacity: 0

    .alarm__body
      bottom: 4rem

      .alarm__cut
        background: #D14B61
        color: #ffffff
        border-radius: 0.625rem
        padding: 10px

      @media (max-width: 425px)
        bottom: 4rem

      .alarm__icon
        background-image: url("/img/icon_star--red.png")
        position: absolute
        width: 3.125rem
        height: 3.125rem
        margin-right: 1.25rem
        background-size: contain
        background-repeat: no-repeat
        transform: translate(0, -50%)
        left: 1.125rem
        top: 50%

      .alarm__content
        text-align: left
        margin-left: 4.5rem

      .alarm__title
        margin: 0 0 0.225rem
        font-size: 1rem
        font-weight: bold

      .alarm__text
        margin: 0
        font-size: 0.875rem

      .alarm__btn
        padding: 0
        position: absolute
        width: 100%
        height: 100%
        transform: none
        top: 0
        left: 0
        margin: 0
        background: transparent

  &.what
    &:before
      opacity: 1

    .alarm__body
      max-height: 600px

  &.convert
    &:before
      opacity: 1

    .alarm__body
      max-height: 600px

      .alarm__message--wrong
        display: block

      .alarm__btn
        display: none

      .alarm__text
        display: block

      .alarm__title:after
        content: ''
        display: block
        margin: 10px auto 0
        font-size: 14px
        font-weight: normal
        background: url("/img/loader.gif") no-repeat
        background-size: cover
        width: 35px
        height: 35px

  &.done
    &:before
      opacity: 1

    .alarm__body
      max-height: 600px

      .alarm__title
        &:after
          content: 'Выберите один из трех вариантов бережного ответа:'
          display: block
          font-weight: 400
          font-size: 18px
          margin-top: 10px

      .alarm__text
        padding: 0.625rem
        background: #DCF8C6
        width: calc(100% - 2.4rem)
        margin: 10px auto 20px
        text-align: left
        border-radius: 0.3rem
        box-shadow: 1px 1px 1px 0 rgba(0, 0, 0, .1)
        position: relative
        font-size: 1rem
        line-height: 1.3rem

      .alarm__icon
        background-image: url("/img/icon_star--green.png")

  &.description
    &:before
      opacity: 1

    .alarm__body
      max-height: 600px

      .alarm__icon
        background-image: url("/img/icon_star--green.png")

  &.hidden
    display: none

  &.close

    .alarm__body
      bottom: -30rem
</style>
