<template>
  <div v-if="debug" class="dev-tools">
    <div class="dev-data">
      <span>Ветка: {{ $route.params.branch }}</span>
      <span>Концовка: {{ $route.params.end }}</span>
      <span>Пол: {{ gender }}</span>
      <span>Имя: {{ name }}</span>
      <span @click="$store.commit('setConfirmCookie', false)">Обнулить куки</span>
    </div>
    <ul class="dev-menu">
      <li class="dev-menu__item">
        <router-link :to="{ name: 'editor'}">Редактор</router-link>
      </li>
      <li class="dev-menu__item">
        <router-link :to="{ name: 'home' }">Главная</router-link>
      </li>
      <li class="dev-menu__item">
        <router-link :to="{ name: 'intro' }">Интро</router-link>
      </li>
      <li class="dev-menu__item">
        <router-link :to="{ name: 'choice-branch' }">Выбор темы</router-link>
      </li>
      <li class="dev-menu__item">
        <router-link :to="{ name: 'setup', params: { type: 'gender' } }">Задать пол</router-link>
      </li>
      <li class="dev-menu__item">
        <router-link :to="{ name: 'setup', params: { type: 'name' } }">Задать имя</router-link>
      </li>
      <li class="dev-menu__item">
        <router-link
          :to="{
            name: 'chat',
            params: {
              gender: gender,
              branch: $route.params.branch || idBranch,
              end: $route.params.end || idBranchEnd,
            },
          }"
        >
          Чат
        </router-link>
      </li>
      <li v-for="(step, idx) in steps" :key="idx" class="dev-menu__item --branch">
        <router-link
          :to="{
            name: 'chat',
            params: {
              gender: gender,
              branch: $route.params.branch || idBranch,
              end: $route.params.end || idBranchEnd,
              step: idx,
            },
          }"
        >
          {{ idx }} - {{ step.type }}
        </router-link>
      </li>
    </ul>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";

export default {
  name: "DevMenu",
  computed: {
    ...mapState({
      idBranch: state => state.idBranch,
      idBranchEnd: state => state.idBranchEnd,
      gender: state => state.gender,
      name: state => state.name,
      debug: state => state.debug,
    }),
    ...mapGetters({
      steps: "steps",
    }),
  },
};
</script>

<style lang="sass">
.dev-tools
  position: absolute
  top: 100px
  right: 10px
  background: #ffffff
  padding: 10px 15px
  font-size: 13px
  z-index: 1000
  width: 150px

.dev-data
  margin-bottom: 10px
  display: flex
  flex-direction: column

.dev-menu
  margin: 0
  padding: 0
  list-style: none

  &__item
    a
      color: #444
      font-size: 13px

      &.router-link-exact-active
        color: #1aa15b

    &.--branch
      padding-left: 10px
</style>
