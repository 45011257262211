<template>
  <div class="choice-branch" id="scrollable">
    <v-scroller v-model="top" :ext="0"></v-scroller>
    <div class="choice-branch__scroll" :style="{ top: '-' + top + 'px' }">
      <div class="choice-branch__title">
        Выберите тему
      </div>
      <div v-for="(branch) in branches" :key="branch.idBranch" class="choice-branch__item" @click="setBranch( parseInt(branch.id.match(/\d+/)))">
        <span class="choice-branch__item-title">{{ branch.name }}</span>
        <span class="choice-branch__item-text">{{ branch.listText }}</span>
        <div class="choice-branch__item-img">
          <img :src="`/collages/icons/${branch.img}.png`" :alt="branch.name"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from "@/api";
import {mapState} from "vuex";
import VScroller from "@/components/v-scroller";

export default {
  name: "ChoiceBranchPage",
  components: {VScroller},
  data: function () {
    return {
      branches: [],
      top: 0
    };
  },
  computed: {
    ...mapState({
      name: state => state.name,
      gender: state => state.gender,
      idBranch: state => state.idBranch,
      idBranchEnd: state => state.idBranchEnd,
    }),
  },
  mounted() {
    this.$store.commit("setBackground", "home");
    this.$store.commit("setShowPhoneOpacity", true);
    api.getBranches().then(data => {
      this.branches = data;
    });
  },
  methods: {
    setBranch: function (id) {
      this.$store.commit("setIdBranch", id);
      this.$store.commit("setBackground", null);

      if (!this.name || !this.gender)
        this.$router.push({
          name: "setup",
          params: {
            type: "gender",
          },
        });
      else
        this.$router.push({
          name: "chat",
          params: {
            gender: this.gender,
            branch: this.idBranch,
            end: this.idBranchEnd,
          },
        });
    },
  },
};
</script>

<style lang="sass">
.choice-branch
  height: 100%
  width: 100%
  padding: 0 1.5rem 2rem
  display: flex
  align-items: center
  flex-direction: column
  overflow: hidden

  &__scroll
    position: relative

  &__title
    margin-top: 60px
    margin-bottom: 20px
    font-size: 1.4rem
    font-weight: 800
    color: #fff

  &__item
    background: #ffffff
    border-radius: 12px
    padding: 1rem 1.3rem
    width: 100%
    position: relative
    margin-bottom: 1rem
    display: flex
    flex-direction: column
    align-items: start
    border: 1px solid #ffffff

    &:hover
      cursor: pointer
      border: 1px solid #00AD5E

    &-title
      color: #000
      font-weight: 800
      font-size: 1rem
      margin-bottom: 6px
      width: 85%

    &-text
      color: #979797
      font-size: 1rem
      width: 85%

    &-img
      position: absolute
      width: 22%
      right: -20px
      top: 50%
      transform: translateY(-50%)

      img
        width: 100%
</style>
