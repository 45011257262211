<template>
  <div class="iframe">
    <iframe src="/pages/end/" frameborder="0" width="100%" height="100%"></iframe>
  </div>
</template>

<script>
export default {
  name: "AboutPage",
};
</script>

<style lang="sass" scoped>
.iframe
  height: 100%
</style>
