<template>
  <table class="notification" @click="$emit('action')">
    <tr>
      <td rowspan="2" class="notification__icon"><c-icon-star color="green"></c-icon-star></td>
      <td rowspan="2" class="notification__title">Вам доступно <br> воспоминание</td>
      <td class="notification__btn --first" @click="toMemory">перейти</td>
    </tr>
    <tr>
      <td class="notification__btn" @click="$emit('close')">закрыть</td>
    </tr>
  </table>
</template>

<script>
import CIconStar from "@/components/design/c-icon-star";

export default {
  name: "TNotification",
  components: { CIconStar },
  props: ["id"],
  methods: {
    toMemory: function () {
      this.$router.push({name: 'memory', params: {id: this.id}, query: {...this.$route.params}})
    }
  }
};
</script>

<style lang="sass">
.notification
  background: #fff
  z-index: 200
  width: 100%
  border-radius: 0.625rem
  box-shadow: 0 10px 16px rgba(0, 0, 0, 0.05)
  border-collapse: collapse
  table-layout: fixed

  &__icon
    padding: 0 10px
    width: 70px
    height: 100%
    max-width: 80px
    max-height: 50px
    position: relative
    .icon-star
      width: 100%
    @media (max-width: 425px)
      margin: 1rem 0.8rem

  &__title
    font-size: 1rem
    font-weight: 800
    color: #000
    word-wrap: break-word

  &__btn
    color: #878787
    font-size: 0.9rem
    border-left: 1px solid #C9C9C9
    padding: 0 15px
    text-align: center
    width: 90px
    @media (max-width: 425px)
      width: 90px

    &:hover
      cursor: pointer

    &.--first
      border-bottom: 1px solid #C9C9C9
</style>
