<template>
  <div>
    <c-phone>
      <template v-if="!$route.meta.slot" v-slot:default>
        <transition name="fade">
          <router-view></router-view>
        </transition>
      </template>
      <template v-else-if="$route.meta.slot === 'outer'" v-slot:outer>
        <transition name="fade">
          <router-view></router-view>
        </transition>
      </template>
    </c-phone>
  </div>
</template>

<script>
import CPhone from "@/components/phone";

export default {
  name: "HomePageWrapper",
  components: {CPhone},
};
</script>

<style lang="sass"></style>
