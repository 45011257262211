<template>
  <vue-typed-js
    v-if="text"
    :strings="[text]"
    :smart-backspace="true"
    :type-speed="100"
    @onComplete="$emit('onComplete')"
    @onStringTyped="$emit('onStringTyped')"
  >
    <span class="typing"></span>
  </vue-typed-js>
</template>

<script>
export default {
  name: "ChatAutoTypeIn",
  props: ["text"],
};
</script>

<style lang="sass"></style>
