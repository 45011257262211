<template>
  <div class="alarm__paginator">
    <div :class="{ active: isPrevActive }" class="alarm__paginator-prev" @click="$emit('prev')"></div>
    <div class="alarm__paginator-dots">
      <div
        v-for="(_, idx) of Array(size)"
        :key="idx"
        class="alarm__paginator-dot"
        :class="{ active: idx === currentIdx }"
      ></div>
    </div>
    <div :class="{ active: isNextActive }" class="alarm__paginator-next" @click="$emit('next')"></div>
  </div>
</template>

<script>
export default {
  name: "TAlarmPagination",
  props: ["size", "currentIdx", "isPrevActive", "isNextActive"],
};
</script>

<style lang="sass">
.alarm__paginator
  transition: bottom 400ms ease-in-out, opacity 300ms ease-in-out
  z-index: 200
  color: #ffffff
  width: 90%
  margin-left: 5%
  display: flex
  flex-direction: row
  align-content: center
  justify-content: space-between
  align-items: center
  @media (max-width: 425px)
    bottom: 1.5rem

  &-prev
    background-image: url(/img/icon__alarm-prev.svg)
    background-repeat: no-repeat
    background-size: contain
    width: 2.5rem
    height: 2.5rem

    &:hover
      cursor: pointer

    &.active
      background-image: url(/img/icon__alarm-prev--active.svg)

  &-next
    background-image: url(/img/icon__alarm-next.svg)
    background-repeat: no-repeat
    background-size: contain
    width: 2.5rem
    height: 2.5rem

    &:hover
      cursor: pointer

    &.active
      background-image: url(/img/icon__alarm-next--active.svg)

  &-dot
    display: inline-block
    margin: 0 10px
    border-radius: 50%
    width: 0.8rem
    height: 0.8rem
    background: #EDEDED

    &.active
      background: #2DBF5E
</style>
