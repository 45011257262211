<template>
  <div class="setup-page">
    <c-icon-star class="setup-page__icon" color="green"></c-icon-star>

    <template v-if="type === 'gender'">
      <div class="setup-page__title">Представьте, что у вас есть ребенок-подросток</div>
      <div class="setup-page__btn">
        <span class="c-btn --white" @click="setGender('b')">Мальчик</span>
        <span class="c-btn --white" @click="setGender('g')">Девочка</span>
      </div>
    </template>
    <template v-else>
      <div class="setup-page__title">Напишите имя</div>
      <name-input @submit="start"></name-input>
      <div class="btn__block">
        <span v-if="name && name.length >= 2" class="c-btn --white" @click="start">Дальше</span>
      </div>
    </template>
  </div>
</template>

<script>
import NameInput from "@/components/nameInput";
import CIconStar from "@/components/design/c-icon-star";
import { mapState } from "vuex";

export default {
  name: "SetupPage",
  components: { CIconStar, NameInput },
  props: ["type"],
  computed: {
    ...mapState({
      idBranch: state => state.idBranch,
      idBranchEnd: state => state.idBranchEnd,
      gender: state => state.gender,
      name: state => state.name,
    }),
  },
  mounted() {
    this.$store.commit("setBackground", null);
    this.$store.commit("setShowPhoneOpacity", true);
  },
  methods: {
    setGender: function(gender) {
      this.$store.commit("setGender", gender);
      this.$router.push({ name: "setup", params: { type: "name" } });
    },
    start: function() {
      this.$router.push({
        name: "chat",
        params: {
          gender: this.gender,
          branch: this.idBranch,
          end: this.idBranchEnd,
        },
      });
    },
  },
};
</script>

<style lang="sass">
.setup-page
  display: flex
  flex-direction: column
  text-align: center
  justify-content: center
  align-items: center
  height: 100%
  color: #fff

  &__icon
    margin-bottom: 3rem

  &__title
    font-size: 1.2rem
    width: 90%
    padding: 0 2rem
    line-height: 140%

  &__btn
    width: 80%
    display: flex
    flex-wrap: wrap
    justify-content: space-around
    padding-top: 1.5rem
</style>
