<template>
  <div class="iframe">
    <!--    <iframe src="http://project80657.tilda.ws/heyconvert-mailout" frameborder="0" width="100%" height="100%"></iframe>-->
    <iframe src="/pages/mail/" frameborder="0" width="100%" height="100%"></iframe>
  </div>
</template>

<script>
export default {
  name: "MailPage"
};
</script>

<style lang="sass" scoped>
.iframe
  height: 100%
</style>
