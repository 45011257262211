import Axios from "axios";

const axios = Axios.create({
  baseURL: "https://api.chatconverter.space/",
});

export default {
  header: null,
  setToken: function (token) {
    this.header = { headers: {
        authorization: "Basic " + token,
      }
    }
  },
  load: async function() {
    const { data } = await axios.get("/stories?_sort=idBranch&_order=asc");
    return data;
  },
  create: async function() {
    const fields = {
      name: "",
      idBranch: null,
      listText: "",
      introText: "",
      steps: [],
    };

    const { data } = await axios.post("/stories", fields, this.header);
    return data;
  },
  delete: async function(id) {
    const { status } = await axios.delete("/stories/" + id, this.header);
    return status;
  },
  getBranch: async function(id) {
    const { data } = await axios.get("/stories/?idBranch=" + id);
    return data;
  },
  getTitles: async function() {
    const { data } = await axios.get("/stories/?main=1", data);
    return data.map(i => {
      return {
        path: i.idBranch,
        title: i.title
      }
    })
  },
  save: async function(id, data) {
    const { status } = await axios.patch("/stories/" + id, data, this.header);
    return status;
  },
  getStickers: async function() {
    const { data } = await axios.get("/stickers");
    return data;
  },
  getBranches: async function() {
    const { data } = await axios.get("/stories?_sort=sort&_order=asc&main=1&idBranch_like=b-");
    return data.map(i => {
      return {
        id: i.idBranch,
        name: i.name,
        listText: i.listText,
        introText: i.introText,
        img: i.img,
        sort: i.sort,
      };
    });
  },
  loadCollage: async function() {
    const { data } = await axios.get("/collage?_sort=sort&_order=asc");
    return data;
  },
  createCollage: async function() {
    const fields = {
      name: "",
      bg: "",
      texts: [],
      objects: {
        desktop: [],
        tablet: [],
        mobile: [],
      },
    };

    const { data } = await axios.post("/collage", fields, this.header);
    return data;
  },
  getCollage: async function(id) {
    const { data } = await axios.get("/collage/" + id);
    return data;
  },
  deleteCollage: async function(id) {
    const { status } = await axios.delete("/collage/" + id, this.header);
    return status;
  },
  saveCollage: async function(id, data) {
    const { status } = await axios.patch("/collage/" + id, data, this.header);
    return status;
  },

  loadArticles: async function() {
    const { data } = await axios.get("/article?_sort=sort&_order=asc");
    return data;
  },
  createArticle: async function() {
    const fields = {
      name: "Назавние",
      bg: "",
      texts: [],
      links: []
    };

    const { data } = await axios.post("/article", fields, this.header);
    return data;
  },
  getArticle: async function(id) {
    const { data } = await axios.get("/article/" + id);
    return data;
  },
  deleteArticle: async function(id) {
    const { status } = await axios.delete("/article/" + id, this.header);
    return status;
  },
  saveArticle: async function(id, data) {
    const { status } = await axios.patch("/article/" + id, data, this.header);
    return status;
  },
  login: async function(token) {
    try {
      await axios.patch('/stickers/1', {},{
        headers: {
          authorization: "Basic " + token,
        }
      })
    } catch (e) {
      return false
    }

    return true
  }
};
