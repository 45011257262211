<template>
  <div class="person">
    <div v-if="name" class="icon">{{ name[0].toUpperCase() }}</div>
    <div v-if="name" class="name">{{ name[0].toUpperCase() + name.slice(1) }}</div>
    <main-menu device="mobile"></main-menu>
  </div>
</template>

<script>
import MainMenu from "@/components/MainMenu";
import { mapState } from "vuex";

export default {
  name: "ChatNav",
  components: { MainMenu },
  computed: {
    ...mapState({
      name: state => state.name,
    }),
  },
};
</script>

<style lang="sass">
.person
  position: relative
  width: 100%
  background: #F8F7F7
  padding: 3rem 1.25rem 0.4rem
  z-index: 200
  transition: top 400ms ease-in
  color: #333
  @media (max-width: 425px)
    padding: 0.5rem 1rem

  .icon
    width: 2.375rem
    height: 2.375rem
    background: #2DBF5E
    float: left
    margin-right: 0.8rem
    border-radius: 50%
    text-align: center
    line-height: 2.375rem
    color: #ffffff
    font-size: 1rem
    font-weight: bold

  .name
    font-size: 1rem
    line-height: 2.25rem
    float: left
</style>
