<template>
  <div :class="['message', `--${direction}`, `--${type}`, `--${status}`]">
    <div class="message__body">
      <slot></slot>
      <div class="message__time">
        {{ time }}
        <span v-if="direction === 'out'" class="message__status"></span>
      </div>
    </div>
    <div class="message__writing">
      <img src="/img/loader.png" alt="" />
      <span>печатает</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "WMessage",
  props: {
    direction: {
      type: String,
      default: "out",
    },
    time: {
      type: String,
    },
    status: {
      type: String,
    },
    type: {
      type: String,
    },
  },
};
</script>

<style lang="sass">
.message
  margin-left: 25px
  margin-top: 0.4rem
  margin-bottom: 0.3rem
  display: flex

  &__body
    background: #ffffff
    display: inline-block
    padding: 0.3rem 0.625rem
    border-radius: 4px
    box-shadow: 1px 1px 1px 0 rgba(0, 0, 0, .1)
    max-width: 65%
    position: relative
    transition: background-color 200ms ease-in

    &:after
      content: ''
      background-image: url("/img/message_arrow--in.png")
      background-size: contain
      height: 14px
      width: 10px
      position: absolute
      left: -10px
      bottom: 5px
      z-index: 1

  &__time
    margin: 2px 0 0 10px
    color: #AEAEAE
    font-size: 0.8rem
    float: right

  &__status
    font-size: 0.8rem

    &:before
      content: ''
      display: inline-block
      background-image: url('/img/message-pending.png')
      background-size: contain
      background-repeat: no-repeat
      height: 0.5rem
      width: 0.8rem

  &__writing
    display: none
    clear: both
    width: 100%
    padding-left: 1.875rem
    margin-top: 0.625rem
    color: #888888
    font-size: 0.8rem

    img
      margin-top: -1rem
      margin-bottom: -0.75rem
      width: 2rem
      position: relative

  &.--delivered
    .message__status:before
      background-image: url("/img/message-delivered.png")
      background-size: contain

  &.--read
    .message__status:before
      background-image: url("/img/message-read.png")
      background-size: contain

  &.--write
    .message__body
      display: none

    .message__writing
      display: block

  &.--out
    margin-right: 15px
    margin-left: 0
    justify-content: flex-end

    .message__body
      background-color: #DCF8C6

      &:after
        content: ''
        background-image: url("/img/message_arrow.png")
        height: 13px
        width: 11px
        position: absolute
        right: -11px
        bottom: 5px
        z-index: 1
        left: inherit

  &.--wrong
    .message__body
      background-color: #FDBBA1

      &:after
        background-image: url("/img/message_arrow-wrong.png")
        height: 13px
        width: 11px
        position: absolute
        right: -11px
        bottom: 5px
        z-index: 1

    .message__time
      color: #DD8F7C

    .message__text
      color: #5B2B17

    .message__status:before
      background-image: url("/img/message-wrong.png")

  &.--sticker
    .message__body
      background-color: transparent
      box-shadow: none

      &:after
        background-image: url("/img/message_arrow-wrong.png")
        height: 13px
        width: 11px
        position: absolute
        right: -11px
        bottom: 5px
        z-index: 1
</style>
