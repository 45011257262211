<template>
  <div></div>
</template>
<script>
export default {
  name: "VScroller",
  props: ["value", "refEl", "inversion", 'ext'],
  data: function () {
    return {
      offset: 0,
      currentX: null,
      currentY: null,
    };
  },
  watch: {
    offset: function () {
      this.$emit("input", this.offset);
    },
    currentX: function (n, o) {
      if (n === null || o === null || n === 0) return;
    },
    currentY: function (n, o) {
      if (n === null || o === null || n === 0) return;
      this.scroll(o - n);
    },
  },
  mounted() {
    const elem = document.querySelector("body");

    if (elem.addEventListener) {
      if ("onwheel" in document) {
        elem.addEventListener("wheel", this.calcScroll);
      } else if ("onmousewheel" in document) {
        elem.addEventListener("mousewheel", this.calcScroll);
      } else {
        elem.addEventListener("MozMousePixelScroll", this.calcScroll);
      }
    } else {
      elem.attachEvent("onmousewheel", this.calcScroll);
    }

    document.addEventListener("keydown", e => {
      if (e.code === "ArrowUp") this.scroll(-30);
      if (e.code === "ArrowDown") this.scroll(30);
    });

    elem.addEventListener("touchstart", this.handleStart, false);
    elem.addEventListener("touchend", this.handleEnd, false);
    elem.addEventListener("touchcancel", this.handleEnd, false);
    elem.addEventListener("touchmove", this.handleMove, false);
  },
  methods: {
    handleStart: function (evt) {
      const touches = evt.changedTouches;
      if (touches.length > 1) return;
      // this.currentX = touches[0].pageX
      this.currentY = touches[0].pageY;
    },
    handleEnd: function () {
      this.currentX = this.currentY = this.startPointX = this.startPointY = null;
    },
    handleMove: function (evt) {
      const touches = evt.changedTouches;
      // this.currentX = touches[0].pageX
      this.currentY = touches[0].pageY;
    },
    calcScroll(e) {
      e = e || window.event;
      const delta = e.deltaY || e.detail || e.wheelDelta;
      this.scroll(delta);
    },
    scroll: function (delta) {
      const el = document.getElementById('scrollable');

      if (!el) return;

      if (this.inversion) {
        if (this.offset + delta > 0)
          this.offset = 0
        else if (el.offsetTop + -delta < 100)
          this.offset += delta
      } else {
        if (this.offset + delta < 0) this.offset = 0;
        else if (this.offset + delta > el.scrollHeight - el.clientHeight + this.ext) {
          this.offset = el.scrollHeight - el.clientHeight + this.ext;
          this.$emit('end')
        }
        else this.offset += delta;
      }
    },
  },
};
</script>
