var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.debug)?_c('div',{staticClass:"dev-tools"},[_c('div',{staticClass:"dev-data"},[_c('span',[_vm._v("Ветка: "+_vm._s(_vm.$route.params.branch))]),_c('span',[_vm._v("Концовка: "+_vm._s(_vm.$route.params.end))]),_c('span',[_vm._v("Пол: "+_vm._s(_vm.gender))]),_c('span',[_vm._v("Имя: "+_vm._s(_vm.name))]),_c('span',{on:{"click":function($event){return _vm.$store.commit('setConfirmCookie', false)}}},[_vm._v("Обнулить куки")])]),_c('ul',{staticClass:"dev-menu"},[_c('li',{staticClass:"dev-menu__item"},[_c('router-link',{attrs:{"to":{ name: 'editor'}}},[_vm._v("Редактор")])],1),_c('li',{staticClass:"dev-menu__item"},[_c('router-link',{attrs:{"to":{ name: 'home' }}},[_vm._v("Главная")])],1),_c('li',{staticClass:"dev-menu__item"},[_c('router-link',{attrs:{"to":{ name: 'intro' }}},[_vm._v("Интро")])],1),_c('li',{staticClass:"dev-menu__item"},[_c('router-link',{attrs:{"to":{ name: 'choice-branch' }}},[_vm._v("Выбор темы")])],1),_c('li',{staticClass:"dev-menu__item"},[_c('router-link',{attrs:{"to":{ name: 'setup', params: { type: 'gender' } }}},[_vm._v("Задать пол")])],1),_c('li',{staticClass:"dev-menu__item"},[_c('router-link',{attrs:{"to":{ name: 'setup', params: { type: 'name' } }}},[_vm._v("Задать имя")])],1),_c('li',{staticClass:"dev-menu__item"},[_c('router-link',{attrs:{"to":{
          name: 'chat',
          params: {
            gender: _vm.gender,
            branch: _vm.$route.params.branch || _vm.idBranch,
            end: _vm.$route.params.end || _vm.idBranchEnd,
          },
        }}},[_vm._v(" Чат ")])],1),_vm._l((_vm.steps),function(step,idx){return _c('li',{key:idx,staticClass:"dev-menu__item --branch"},[_c('router-link',{attrs:{"to":{
          name: 'chat',
          params: {
            gender: _vm.gender,
            branch: _vm.$route.params.branch || _vm.idBranch,
            end: _vm.$route.params.end || _vm.idBranchEnd,
            step: idx,
          },
        }}},[_vm._v(" "+_vm._s(idx)+" - "+_vm._s(step.type)+" ")])],1)})],2)]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }