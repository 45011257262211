import Vue from "vue";
import App from "./App.vue";
// import App from "./pages/collage/index";
import store from "./store";
import router from "./router";
import "@csstools/normalize.css";
import "./assets/css/scrollbar.sass";
import VueTypedJs from "vue-typed-js";
import Vue2TouchEvents from "vue2-touch-events";
import VueGtag from "vue-gtag";
import api from "@/api";

Vue.use(VueGtag, {
  config: { id: window.GA_ID},
  enabled: false,
  pageTrackerTemplate: function (to) {
    return {
      page_title: document.title,
      page_location: to.path
    }
  },
}, router);

Vue.use(VueTypedJs);
Vue.use(Vue2TouchEvents);
Vue.config.productionTip = false;

Vue.directive("scroll", {
  inserted: function(el, binding) {
    let f = function(evt) {
      if (binding.value(evt, el)) {
        el.removeEventListener("scroll", f);
      }
    };
    el.addEventListener("scroll", f);
  },
});

(async function (){
  window.chatMeta = await api.getTitles()

  new Vue({
    router,
    store,
    render: h => h(App),
  }).$mount("#app");
})();