<template>
  <wrapper-page bg="1">
    <template slot="header">
      <c-icon-star color="green"></c-icon-star>
      <h1>Воспоминания</h1>
      <p>Помните себя подростком? Когда вдруг захватывают яркие и сложные эмоции и нужно решать множество важных и серьезных вопросов? Чтобы вам было легче погрузиться в прошлое и приблизиться к пониманию своего ребенка, мы собрали истории нынешних взрослых об их эмоциях и переживаниях в возрасте 13–17 лет.</p>
    </template>
    <template slot="body">
      <div class="memory">
        <router-link v-for="(memory) in memories" :key="memory.id" :to="{name: 'memory', params: {id: memory.id}}" class="memory-item">
          <div class="memory-item__img">
            <img :src="memory.preview" alt="">
          </div>
          <div class="memory-item__title">
            {{memory.title}}
          </div>
          <div class="memory-item__desc">{{memory.description}}</div>
        </router-link>
      </div>
    </template>
  </wrapper-page>
</template>

<script>
import CIconStar from "@/components/design/c-icon-star";
import api from "@/api";
import WrapperPage from "@/components/wrapper/w-page";
export default {
  name: "MemoriesPage",
  components: {WrapperPage, CIconStar },
  data: function () {
    return {
      memories: []
    }
  },
  async mounted() {
    this.$store.commit("setBackground", "page");
    const m = await api.loadCollage()
    if (m.length) {
      this.memories = m.map(i => ({
        id: i.id,
        title: i.name,
        preview: i.preview,
        description: i.description
      }))
    }
  },
};
</script>

<style lang="sass">
@import "src/assets/css/media"

.memory
  display: flex
  flex-wrap: wrap
  justify-content: center
  padding-bottom: 50px
  &-item
    max-width: 350px
    width: 100%
    min-width: 250px
    padding: 0 10px
    margin-bottom: 20px
    text-decoration: none
    @include media-sm
      width: 50%
      margin-bottom: 40px
    @include media-lg
      width: 33%
      margin-bottom: 60px
    @include media-xl
      width: 25%
      margin-bottom: 60px
    &__img
      margin-bottom: 5px
      @include media-sm
        margin-bottom: 10px
      @include media-lg
        margin-bottom: 15px
      img
        width: 100%
    &__num
      width: 20px
      display: inline-block
    &__title
      font-size: 18px
      margin-bottom: 5px
      color: #000
      font-weight: bold
    &__desc
      font-size: 15px
      color: #979797
      padding: 0 10px 0 0

</style>
