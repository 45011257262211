<template>
  <div class="phone__inner">
    <div class="phone centered" :class="[cssActiveApp]">
      <div class="phone__border phone__border--top"></div>
      <div class="phone__border phone__border--left"></div>
      <div class="phone__border phone__border--right"></div>
      <div class="phone__border phone__border--bottom"></div>
      <div :class="['phone__wrapper', { '--opacity': showPhoneOpacity }]">
        <div class="phone__content">
          <slot></slot>
        </div>
      </div>
    </div>
    <slot name="outer"></slot>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "CPhone",
  props: ["cssActiveApp", ""],
  computed: {
    ...mapState({
      showPhoneOpacity: state => state.showPhoneOpacity,
    }),
  },
};
</script>

<style lang="sass">

.centered
  position: absolute
  top: 50%
  left: 50%
  transform: translate(-50%, -50%)

.phone__border
  background-size: 100% 100%
  /*background-repeat: no-repeat*/
  z-index: 1000
  position: absolute
  /*display: none*/
  @media (max-width: 425px)
    display: none

  &--top
    background-image: url("/img/phone__mask-top.png")
    width: 100%
    height: 6.555555555%
    top: 0
    left: 50%
    transform: translateX(-50%)

  &--left
    background-image: url("/img/phone__mask-left.png")
    background-size: 100% 100%
    width: 8.5714285714%
    height: 88.563106796%
    top: 6.555555555%
    left: 0

  &--right
    background-image: url("/img/phone__mask-right.png")
    background-size: 100% 100%
    width: 8.5714285714%
    height: 88.563106796%
    top: 6.555555555%
    right: 0

  &--bottom
    background-image: url("/img/phone__mask-bottom.png")
    width: 100%
    height: 4.97%
    bottom: 0
    left: 50%
    transform: translateX(-50%)

.phone
  // 1,9619047619
  height: 85vh
  max-height: 824px
  min-height: 500px
  width: 43.4vh
  max-width: 420px
  min-width: 254px
  overflow: hidden
  z-index: 100
  /*transition: opacity 500ms ease-in*/
  @media (max-height: 960px) and (min-height: 710px)
    height: 90vh
    max-height: 824px
    min-height: 500px
    width: 45.9vh
    max-width: 420px
    min-width: 254px
  @media (max-height: 709px) and (min-height: 500px)
    height: 100vh
    max-height: 824px
    min-height: 500px
    width: 51vh
    max-width: 420px
    min-width: 254px
  @media (max-width: 425px)
    height: 100%
    min-width: 100%
    min-height: 100%
    width: auto

  &__content
    position: relative
    z-index: 100
    height: 100%
    width: 100%

  &__wrapper
    //display: flex
    //align-items: center
    //justify-content: center
    //flex-direction: column
    width: 90.5%
    height: 98%
    top: 1%
    left: 4.5%
    border-radius: 30px
    overflow: hidden
    position: absolute
    @media (max-width: 425px)
      width: 100%
      height: 100%
      top: 0
      left: 0
      border-radius: 0

    &:before
      content: ''
      position: absolute
      width: 100%
      height: 100%
      background: rgba(0, 0, 0, .8)
      z-index: 1
      display: none

    &.--opacity
      &:before
        display: block

  &__apps
    position: absolute
    top: 0
    left: 0
    width: 200%
    height: 100%
    transition: left 300ms cubic-bezier(.34, .17, .76, .84)
    overflow: hidden

  &__app
    position: absolute
    width: 50%
    height: 100%
    background: #f2f2f9
    border-radius: 30px
    overflow: hidden
    transition: all 200ms cubic-bezier(.34, .17, .76, .84)
    transform: scale(1, 1)
    @media (max-width: 425px)
      border-radius: 0

    &.collage
      left: 50%
      text-align: center

      &:after
        content: ''
        position: absolute
        width: 100%
        height: 100%
        background: #393941 url("/img/icon_star--red.svg") center 10rem no-repeat
        left: 0
        top: 0
        opacity: 1
        transition: opacity 300ms ease
        border-radius: 30px
        z-index: 100

  &.collage
    .phone__apps
      left: -100%
</style>
