<template>
  <div class="input-name">
    <form @submit.prevent="$emit('submit')">
      <input v-model="name" />
    </form>
  </div>
</template>

<script>
export default {
  name: "NameInput",
  computed: {
    name: {
      get() {
        return this.$store.state.name;
      },
      set(value) {
        this.$store.commit("setName", value);
      },
    },
  },
};
</script>

<style lang="sass" scoped>
.input-name
  border-bottom: 1px solid #ffffff
  width: 10rem
  margin: 0 auto 1rem
input
  background: transparent
  border: none
  font-size: 1.3rem
  line-height: 2.5rem
  width: 100%
  outline: none
  color: #ffffff
  text-align: center
  margin-top: 2rem
</style>
