<template>
  <div v-touch:swipe.right="$emit('prev')" v-touch:swipe.left="$emit('next')" class="alarm">
    <t-alarm-body
      v-touch:swipe.left="nextText"
      v-touch:swipe.right="prevText"
      :title="title"
      :content="cContent"
      :right-message="rightMessage"
      :wrong-message="wrongMessage"
      :btn-text="btn"
      :show-btn="showBtn"
      @next="$emit('next-step', currentIdxText)"
    >
      <t-alarm-pagination
        v-if="showPagination"
        :size="content.length"
        :current-idx="currentIdxText"
        :is-prev-active="isPrevActive"
        :is-next-active="isNextActive"
        @prev="prevText"
        @next="nextText"
      >
      </t-alarm-pagination>
    </t-alarm-body>
  </div>
</template>

<script>
import TAlarmPagination from "@/components/type/alarm/t-alarm-pagination";
import TAlarmBody from "@/components/type/alarm/t-alarm-body";

export default {
  name: "TAlarmWrapper",
  components: { TAlarmBody, TAlarmPagination },
  props: ["step", "title", "content", "btn", "wrongMessage", "rightMessage"],
  data: function() {
    return {
      height: 42,
      currentIdxText: 0,
    };
  },
  computed: {
    showBtn: function() {
      return (
        !(
          this.content !== null &&
          typeof this.content === "object" &&
          this.content.length !== this.currentIdxText + 1
        ) || this.step === "done"
      );
    },
    cContent: function() {
      if (this.content !== null && typeof this.content === "object") return this.content[this.currentIdxText];

      return this.content;
    },
    showPagination: function() {
      return this.content !== null && typeof this.content === "object" && this.content.length > 1;
    },
    isNextActive: function() {
      return this.currentIdxText < this.content.length - 1;
    },
    isPrevActive: function() {
      return this.currentIdxText > 0;
    },
  },
  watch: {
    content: function(n, o) {
      if (n !== o) this.currentIdxText = 0;
    },
  },
  methods: {
    nextStep: function() {
      this.$emit("next-step");
    },
    refreshHeight: function() {
      setTimeout(() => {
        this.height = this.$refs.alarm.clientHeight;
      }, 1);
    },
    prevText: function() {
      if (this.isPrevActive) this.currentIdxText--;
    },
    nextText: function() {
      if (this.isNextActive) this.currentIdxText++;
    },
  },
};
</script>

<style lang="sass">

.alarm
  width: 100%

  &:before
    z-index: 100
    position: absolute
    left: 0
    top: 0
    content: ''
    width: 100%
    height: 100%
    background: rgba(0, 0, 0, .85)
    opacity: 0
    transition: opacity 300ms ease
</style>
